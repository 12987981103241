<template>
  <div class="container-fluid pt-3">
    <!-- <ul>
      <li v-for="category in currentCategories" :key="category.id">
        {{ category.category }}
      </li>
    </ul> -->
    <!-- <pre> -->
    <!-- {{ users }} -->
    <!-- {{ addressCategories }} -->
    <!-- {{ address }} -->
    <!-- {{currentCategories}} -->
    <!-- </pre> -->
    <Modal @close="toggleModal" :modalActive="modalActive">
      <template v-slot:header>{{ t("general.edit") }}: {{ t("general.categories") }}</template>
      <template v-slot:body>
        <div v-for="category in currentCategories" :key="category.id" class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            v-model="category.hasCategory"
            :id="category.id"
          />
          <label class="form-check-label" :for="'category_' + category.id">{{ category.category }}</label>
        </div>
      </template>
      <template v-slot:footer>
        <button @click="resetCategories" type="button" class="btn btn-secondary">{{ t("general.reset") }}</button>
        <button @click="toggleModal" type="button" class="btn btn-primary">{{ t("general.apply_changes") }}</button>
      </template>
    </Modal>

    <Modal @close="toggleDeleteModal" :modalActive="delteActiveModal">
      <template v-slot:header> {{ t("address.del_address") }} </template>
      <template v-slot:body>
        <p>{{ t("address.delete_dialog") }}</p>
        <p>{{ t("address.delete_dialog_hint") }}</p>
      </template>
      <template v-slot:footer>
        <button @click="toggleDeleteModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
        <button @click="delete_address" type="button" class="btn btn-danger">{{ t("general.delete") }}</button>
      </template>
    </Modal>

    <div v-if="Object.keys(address).length > 0" class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-0">{{ t("address.address") }} {{ t("general.edit") }}</h1>
          <div class="subtitle-name mb-3 text-center">{{ displayName }}</div>
        </div>
      </div>
      <!-- nav -->
      <div class="row g-3">
        <div class="col-md-12">
          <AddressNavigation :categories="address.categories" :id="$route.params.id"></AddressNavigation>
        </div>
      </div>
      <!-- edit -->
      <div class="row row-cols-auto g-3 align-items-center mt-2">
        <div class="col">
          <small>ID: {{ address.id }}</small>
        </div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="address_is_person"
              v-model="address.is_person"
              true-value="1"
              false-value="0"
            />
            <label class="form-check-label" for="address_is_person">{{ t("address.person") }}</label>
          </div>
        </div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="address_is_company"
              v-model="address.is_company"
              true-value="1"
              false-value="0"
            />
            <label class="form-check-label" for="address_is_company">{{ t("address.company") }}</label>
          </div>
        </div>
        <div class="col">
          <div class="row row-cols-auto g-3 align-items-center">
            <label for="address_valuation" class="col col-form-label">{{ t("address.valuation") }}</label>
            <div class="col-sm-5 col-md-6 pt-2">
              <input
                type="range"
                v-model="address.valuation"
                class="form-range"
                min="1"
                max="7"
                step="1"
                id="address_valuation"
              />
            </div>
            <div class="col">
              <span class="badge bg-secondary" v-text="address.valuation ? address.valuation : '-'"></span>
            </div>
          </div>
        </div>
        <div class="col">
          <template v-for="category in currentCategories" :key="category.id">
            <div v-if="category.hasCategory" class="d-inline-block me-1 mb-1 pe-3 badge rounded-pill bg-primary">
              <svg
                @click="deleteCategory(category.id)"
                class="bi ms-1 cursor-pointer"
                width="20"
                height="20"
                fill="currentColor"
                v-if="!isArtist || true"
              >
                <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
              </svg>
              <svg class="bi ms-1 cursor-pointer" width="20" height="20" fill="currentColor" v-else>
                <use xlink:href="/assets/img/bootstrap-icons.svg#person-fill"></use>
              </svg>
              {{ category.category }}
            </div>
          </template>
        </div>
        <div class="col" v-if="!isArtist || true">
          <button
            @click="toggleModal"
            :title="t('general.edit') + ': ' + t('general.categories')"
            type="button"
            class="btn btn-success d-block btn-icon mt-2 mt-md-0"
          >
            <svg class="bi" width="20" height="20" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#pencil"></use>
            </svg>
            {{ t("general.categories") }}
          </button>
        </div>
      </div>
      <div class="g-3 row">
        <div class="col-md-12">
          <label for="address_name" class="form-label">{{ t("address.name") }}</label>
          <input type="text" class="form-control" id="address_name" v-model="address.name" />
        </div>
        <div class="col-md-2">
          <label for="address_salutation" class="form-label">{{ t("address.salutation") }}</label>
          <input type="text" class="form-control" id="address_salutation" v-model="address.salutation" />
        </div>
        <div class="col-md-5">
          <label for="address_forename" class="form-label">{{ t("address.forename") }}</label>
          <input type="text" class="form-control" id="address_forename" v-model="address.forename" />
        </div>
        <div class="col-md-5">
          <label for="address_surname" class="form-label">{{ t("address.surname") }}</label>
          <input type="text" class="form-control" id="address_surname" v-model="address.surname" />
        </div>
        <div class="col-md-6">
          <label for="address_email" class="form-label">
            {{ t("address.email") }}
            <a :href="'mailto:' + address.email">
              <svg class="bi ms-1" width="24" height="24" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#envelope-fill"></use>
              </svg>
            </a>
          </label>
          <input type="email" class="form-control" id="address_email" v-model="address.email" />
        </div>
        <div class="col-md-6">
          <label for="address_website" class="form-label">
            {{ t("address.website") }}
            <a :href="website.startsWith('http') ? website : 'https://' + website" target="_blank">
              <svg class="bi ms-1" width="24" height="24" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#arrow-right-circle-fill"></use>
              </svg>
            </a>
          </label>
          <input type="text" class="form-control" id="address_website" v-model="address.website" />
        </div>
        <div class="col-md-6">
          <label for="address_description" class="form-label">{{ t("address.description") }}</label>
          <input type="text" class="form-control" id="address_description" v-model="address.description" />
        </div>
        <div class="col-md-6">
          <label for="address_vat" class="form-label">{{ t("address.vat") }}</label>
          <input type="text" class="form-control" id="address_vat" v-model="address.vat" />
        </div>
        <div class="col-md-12">
          <label for="address_comment" class="form-label">{{ t("address.comment") }}</label>
          <textarea
            type="text"
            class="form-control"
            id="address_comment"
            rows="10"
            v-model="address.comment"
          ></textarea>
        </div>
        <!-- Address -->
        <div class="col-sm-4 col-lg-2">
          <label for="address_street" class="form-label">{{ t("address.street") }}</label>
          <input type="text" class="form-control" id="address_street" v-model="address.street" />
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="address_plz" class="form-label">{{ t("address.plz") }}</label>
          <input type="text" class="form-control" id="address_plz" v-model="address.plz" />
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="address_city" class="form-label">{{ t("address.city") }}</label>
          <input type="text" class="form-control" id="address_city" v-model="address.city" />
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="address_country" class="form-label">{{ t("address.country") }}</label>
          <select
            class="form-select"
            id="address_country"
            :aria-label="t('address.country')"
            v-model="address.country_id"
          >
            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
          </select>
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="address_postbox" class="form-label">{{ t("address.postbox") }}</label>
          <input type="text" class="form-control" id="address_postbox" v-model="address.postcode" />
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="address_postcode_pobox" class="form-label"
            >{{ t("address.plz") }} {{ t("address.postbox") }}</label
          >
          <input type="text" class="form-control" id="address_postcode_pobox" v-model="address.postcode_pobox" />
        </div>
        <!-- phone -->
        <div class="col-md-4 col-xxl-2">
          <label for="address_tel_int_area_code" class="form-label">{{ t("address.tel_int_area_code") }}</label>
          <input type="text" class="form-control" id="address_tel_int_area_code" v-model="address.tel_int_area_code" />
        </div>
        <div class="col-md-4 col-xxl-2">
          <label for="address_tel_city_area_code" class="form-label">{{ t("address.tel_city_area_code") }}</label>
          <input
            type="text"
            class="form-control"
            id="address_tel_city_area_code"
            v-model="address.tel_city_area_code"
          />
        </div>
        <div class="col-md-4 col-xxl-2">
          <label for="address_tel" class="form-label">
            {{ t("address.tel") }}
            <a v-if="phone" :href="'tel:' + phone" class="text-decoration-none">
              <svg class="bi mx-1" width="24" height="24" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#telephone-fill"></use>
              </svg>
              <span class="fz-xs">{{ phone }}</span>
            </a>
          </label>
          <input type="text" class="form-control" id="address_tel" v-model="address.tel" />
        </div>
        <div class="col-md-4 col-xxl-2">
          <label for="address_mobile_int_area_code" class="form-label">{{ t("address.mobile_int_area_code") }}</label>
          <input
            type="text"
            class="form-control"
            id="address_mobile_int_area_code"
            v-model="address.mobile_int_area_code"
          />
        </div>
        <div class="col-md-4 col-xxl-2">
          <label for="address_mobile_area_code" class="form-label">{{ t("address.mobile_area_code") }}</label>
          <input type="text" class="form-control" id="address_mobile_area_code" v-model="address.mobile_area_code" />
        </div>
        <div class="col-md-4 col-xxl-2">
          <label for="address_mobile" class="form-label">
            {{ t("address.mobile") }}
            <a v-if="mobile" :href="'tel:' + mobile" class="text-decoration-none">
              <svg class="bi mx-1" width="24" height="24" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#phone-fill"></use>
              </svg>
              <span class="fz-xs">{{ mobile }}</span>
            </a>
          </label>
          <input type="text" class="form-control" id="address_mobile" v-model="address.mobile" />
        </div>
        <!-- editor -->
        <div class="w-100"></div>
        <div class="col-md-4 col-lg-2" v-if="activeUsers.length > 0">
          <label for="address_editor" class="form-label">{{ t("general.editor") }}</label>
          <select class="form-select" id="address_editor" :aria-label="t('general.editor')" v-model="address.editor">
            <option v-for="editor in activeUsers" :key="editor.id" :value="editor.id">{{ editor.name }}</option>
          </select>
        </div>
        <div class="col-md-8 col-lg-10 d-flex flex-column justify-content-end align-items-start">
          <div v-if="createdBy">Erzeugt von: {{ createdBy }}</div>
          <div v-if="updatedBy">Letzte Aktualiserung durch: {{ updatedBy }}</div>
          <div v-if="address.updated_at">
            {{ t("general.updated_at") }} : {{ localDateTime(address.updated_at, "de-DE") }}
          </div>
        </div>
        <!-- save -->
        <div class="col-md-12 d-flex justify-content-between">
          <button @click="save" type="button" class="btn d-block btn-success ps-1" :title="t('general.save')">
            <svg class="bi" width="24" height="24" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
            </svg>
            {{ t("general.save") }}
          </button>
          <button
            @click="toggleDeleteModal"
            type="button"
            class="btn d-block btn-danger text-right ps-1"
            :title="t('address.del_address')"
          >
            <svg class="bi" width="24" height="24" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
            </svg>
            {{ t("address.del_address") }}
          </button>
        </div>
        <!-- map -->
        <div v-if="address.lat" class="col-md-12 gws_map_container">
          <l-map v-model:zoom="map_zoom" :center="[address.lat, address.lon]">
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            ></l-tile-layer>
            <l-control-layers />
            <l-marker :lat-lng="[address.lat, address.lon]">
              <l-popup>{{ address.name }}</l-popup>
            </l-marker>
          </l-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import storeConfig from "../../store/config";
import { useApiQuery } from "@/composables/useApiQueries";
import Modal from "@/components/Modal.vue";
import AddressNavigation from "@/components/navigation/Address.vue";
import { useToast } from "vue-toastification";
import { LMap, LTileLayer, LMarker, LControlLayers, LPopup } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import { localDateTime } from "@/composables/utilities";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlLayers,
    // LTooltip,
    LPopup,
    Modal,
    AddressNavigation,
  },
  name: "AddressEdit",
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const store = useStore();
    const router = useRouter();
    const address = ref({});
    const savedPostalAddress = ref({});
    const addressCategories = ref([]);
    const { result: categories, query: getCategories } = useApiQuery({ url: "category", defaultVal: [] });
    const currentCategories = ref([]);
    const isArtist = ref(false);
    const countries = ref({});
    const users = ref([]);
    const activeUsers = computed(() => {
      return users.value.filter((user) => user.status === "active");
    });
    const { t } = useI18n();
    const toast = useToast();
    const map_zoom = ref(16);
    const modalActive = ref(false);
    const delteActiveModal = ref(false);
    const currentUser = computed(() => store.getters.user);
    const createdBy = computed(() => {
      const user = users.value.find((user) => user.id === address.value.created_by);
      return user ? user.name : "";
    });
    const updatedBy = computed(() => {
      const user = users.value.find((user) => user.id === address.value.updated_by);
      return user ? user.name : "";
    });

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };

    const toggleDeleteModal = () => {
      delteActiveModal.value = !delteActiveModal.value;
    };

    async function getAddress() {
      if (route.params.id) {
        try {
          await store.dispatch("addLoading", 1);
          let result = await axios.get(storeConfig.apiBaseUrl + "addresses/" + route.params.id);
          address.value = result.data;
          savedPostalAddress.value = {
            street: address.value.street,
            city: address.value.city,
            country: address.value.country && address.value.country.name ? address.value.country.name : null,
          };
          addressCategories.value = address.value.categories;
          checkIsArtist();
          setGeocoding();
        } catch (err) {
          // console.log(address.value, err);
          if (err.response && err.response.status == 401) {
            store.dispatch("logout");
            router.push({ name: "Login" });
          }
        } finally {
          await store.dispatch("addLoading", -1);
        }
      }
    }

    async function getCountries() {
      try {
        await store.dispatch("addLoading", 1);
        let result = await axios.get(storeConfig.apiBaseUrl + "country");
        countries.value = result.data;
      } catch (err) {
        if (err.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function getUsers() {
      try {
        await store.dispatch("addLoading", 1);
        let result = await axios.get(storeConfig.apiBaseUrl + "user");
        users.value = result.data;
      } catch (err) {
        if (err.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function setGeocoding(force) {
      if (
        ((address.value.lat === null && address.value.lon === null) || force) &&
        address.value.city &&
        address.value.country
      ) {
        // console.log("setGeocoding");
        try {
          let result = await axios.get("https://nominatim.openstreetmap.org", {
            params: {
              street: address.value.street,
              city: address.value.city,
              country: address.value.country.name,
              format: "json",
            },
            // important, otherwhise Authorization headers will be sent:
            transformRequest: [
              (data, headers) => {
                delete headers.common.Authorization;
                return data;
              },
            ],
          });
          if (result.data[0].lat.length) {
            address.value.lat = result.data[0].lat;
            address.value.lon = result.data[0].lon;
            // let update =
            await axios.put(storeConfig.apiBaseUrl + "addresses/" + route.params.id, {
              lat: result.data[0].lat,
              lon: result.data[0].lon,
            });
            // console.log(update.data);
          }
          // console.log(result.data);
        } catch (err) {
          // console.log(err.response);
        }
      }
    }

    function checkIsArtist() {
      if (address.value.categories.length) {
        for (let i = 0; i < address.value.categories.length; i++) {
          if (address.value.categories[i]["Künstler"] == 1 || address.value.categories[i]["category"] == "Künstler") {
            isArtist.value = true;
            break;
          }
        }
      }
    }

    function setCurrentCategories() {
      currentCategories.value = [];
      for (let i = 0; i < categories.value.length; i++) {
        let hasCategory = false;
        for (let j = 0; j < addressCategories.value.length; j++) {
          if (categories.value[i].id == addressCategories.value[j].id) {
            hasCategory = true;
            break;
          }
        }
        currentCategories.value.push({
          id: categories.value[i].id,
          category: categories.value[i].category,
          hasCategory: hasCategory,
        });
      }
      // console.log(
      //   currentCategories.value.filter((category) => category.hasCategory == true).map((category) => category.id)
      // );
    }

    function resetCategories() {
      currentCategories.value = [];
      setCurrentCategories();
      modalActive.value = false;
    }

    function deleteCategory(id) {
      currentCategories.value = currentCategories.value.map((category) =>
        category.id == id ? { ...category, hasCategory: false } : category
      );
    }

    async function getAll() {
      // await getAddress();
      // await getCountries();
      // await getUsers();
      // await getCategories();
      await Promise.all([getAddress(), getCountries(), getUsers(), getCategories()]);
      setCurrentCategories();
      // console.log(currentUser.value.id);
    }

    const phone = computed(() => {
      let phone = "";
      if (address.value.tel) {
        if (address.value.tel_int_area_code) {
          phone = "+" + address.value.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
          if (address.value.tel_city_area_code) {
            phone += address.value.tel_city_area_code.replace(/\s/g, "").replace(/^0+/, "");
          }
        } else if (address.value.tel_city_area_code) {
          phone = address.value.tel_city_area_code.replace(/\s/g, "");
        }
        phone += address.value.tel.replace(/\s/g, "");
      }
      return phone;
    });

    const mobile = computed(() => {
      let mobile = "";
      if (address.value.mobile) {
        if (address.value.mobile_int_area_code) {
          mobile = "+" + address.value.mobile_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
          if (address.value.mobile_area_code) {
            mobile += address.value.mobile_area_code.replace(/\s/g, "").replace(/^0+/, "");
          }
        } else if (address.value.tel_int_area_code) {
          mobile = "+" + address.value.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
          if (address.value.mobile_area_code) {
            mobile += address.value.mobile_area_code.replace(/\s/g, "").replace(/^0+/, "");
          }
        } else if (address.value.mobile_area_code) {
          mobile = address.value.mobile_area_code.replace(/\s/g, "");
        }
        mobile += address.value.mobile.replace(/\s/g, "");
      }
      return mobile;
    });

    const fax = computed(() => {
      let fax = "";
      if (address.value.fax) {
        if (address.value.tel_int_area_code) {
          fax = "+" + address.value.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
          if (address.value.tel_city_area_code) {
            fax += address.value.tel_city_area_code.replace(/\s/g, "").replace(/^0+/, "");
          }
        } else if (address.value.tel_city_area_code) {
          fax = address.value.tel_city_area_code.replace(/\s/g, "");
        }
        fax += address.value.fax.replace(/\s/g, "");
      }
      return fax;
    });

    async function save() {
      if (
        savedPostalAddress.value.street !== address.value.street ||
        savedPostalAddress.value.city !== address.value.city ||
        (address.value.country &&
          address.value.country.name &&
          savedPostalAddress.value.country !== address.value.country.name)
      ) {
        await setGeocoding(true);
      }
      const data = {
        is_person: address.value.is_person,
        is_company: address.value.is_company,
        name: address.value.name,
        salutation: address.value.salutation,
        description: address.value.description,
        surname: address.value.surname,
        forename: address.value.forename,
        email: address.value.email,
        street: address.value.street,
        plz: address.value.plz,
        postcode: address.value.postcode,
        city: address.value.city,
        country_id: address.value.country_id,
        website: address.value.website,
        postcode_pobox: address.value.postcode_pobox,
        tel_int_area_code: address.value.tel_int_area_code,
        tel_city_area_code: address.value.tel_city_area_code,
        tel: address.value.tel,
        mobile_int_area_code: address.value.mobile_int_area_code,
        mobile_area_code: address.value.mobile_area_code,
        mobile: address.value.mobile,
        fax: address.value.fax,
        comment: address.value.comment,
        valuation: address.value.valuation,
        editor: address.value.editor,
        updated_by: currentUser.value.id,
        vat: address.value.vat,
        categories: currentCategories.value
          .filter((category) => category.hasCategory == true)
          .map((category) => category.id),
      };
      try {
        await store.dispatch("addLoading", 1);
        await axios.put(storeConfig.apiBaseUrl + "addresses/" + route.params.id, data);
        // console.log(result);
        getAll();
        toast.success(t("address.address") + " " + t("general.successfully") + " " + t("general.edited") + ".");
      } catch (error) {
        if (error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function delete_address() {
      try {
        await store.dispatch("addLoading", 1);
        await axios.delete(storeConfig.apiBaseUrl + "addresses/" + route.params.id);
        toggleDeleteModal();
        toast.success(t("address.address") + " " + t("general.successfully") + " " + t("general.deleted") + ".");
        router.push({ name: "Addresses" });
      } catch (error) {
        if (error.response && error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    getAll();

    watch(
      () => route.params.id,
      () => getAll()
    );

    return {
      id,
      address,
      displayName: computed(() => {
        if (address.value.name) {
          return address.value.name;
        } else if (address.value.forename && address.value.surname) {
          return address.value.forename + " " + address.value.surname;
        } else if (address.value.forename) {
          return address.value.forename;
        } else if (address.value.surname) {
          return address.value.surname;
        } else {
          return "";
        }
      }),
      countries,
      addressCategories,
      phone,
      mobile,
      fax,
      categories,
      currentCategories,
      resetCategories,
      isArtist,
      users,
      activeUsers,
      t,
      website: computed(() =>
        address.value.website
          ? address.value.website.startsWith("http")
            ? address.value.website
            : "https://" + address.value.website
          : ""
      ),
      map_zoom,
      save,
      modalActive,
      toggleModal,
      deleteCategory,
      createdBy,
      updatedBy,
      delteActiveModal,
      toggleDeleteModal,
      delete_address,
      localDateTime,
    };
  },
};
</script>
